import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Drawer as AntdDrawer, Button, Layout } from 'antd';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import tw from 'twin.macro';

import useIsWindowWidthBelowThan from '@/hooks/useIsWindowWidthBelowThan';

import Account from './Account';
import Menu from './Menu';

interface Props {
  children: ReactNode;
}

const AuthenticatedLayout = ({ children }: Props) => {
  const isMobile = useIsWindowWidthBelowThan(768);
  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setDrawer(false);
    }
  }, [isMobile]);

  return (
    <Layout css={tw`h-full`}>
      <Drawer
        placement="left"
        width={280}
        onClose={() => setDrawer(false)}
        open={drawer}
      >
        <Menu />

        <div css={tw`flex flex-col gap-[24px] mt-[64px]`}>
          <Button type="default" block icon={<LogoutOutlined />}>
            로그아웃
          </Button>

          <Account />
        </div>
      </Drawer>

      <Layout.Sider
        hidden={isMobile}
        css={tw`p-[40px 32px] bg-[#edeef1]`}
        width={280}
        collapsed={false}
        collapsedWidth={0}
      >
        <Menu />
      </Layout.Sider>

      <Layout>
        <Layout.Header
          css={[
            tw`flex flex-row justify-end items-center h-[64px] gap-[8px] p-[16px 40px]`,
            tw`bg-white border-b-[1px] border-b-[#f0f0f0]`,
            tw`mobile:(justify-start p-[12px 20px] h-[48px] border-none)`,
          ]}
        >
          {isMobile ? (
            <button
              css={tw`flex items-center justify-center w-[24px] h-[24px] p-0 text-[24px] text-[rgba(0, 0, 0, 0.45)]`}
              type="button"
              onClick={() => setDrawer(true)}
            >
              <MenuOutlined />
            </button>
          ) : (
            <>
              <Account />
              <Button type="text" icon={<LogoutOutlined />}>
                로그아웃
              </Button>
            </>
          )}
        </Layout.Header>

        <Layout.Content
          css={[tw`p-[48px 40px 0]`, tw`mobile:(px-[20px])`, tw`bg-white`]}
        >
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

const Drawer = styled(AntdDrawer)`
  && {
    ${tw`bg-white`}

    .ant-drawer-header {
      ${tw`p-[12px 20px] border-none`}

      .ant-drawer-header-title {
        ${tw`flex flex-row justify-end`}
      }

      button {
        ${tw`m-0`}
      }
    }

    .ant-drawer-body {
      ${tw`p-[36px 20px]`}
    }
  }
`;

export default AuthenticatedLayout;
