import { Menu as AntdMenu } from 'antd';
import type { ItemType, MenuItemType } from 'antd/es/menu/interface';
import tw from 'twin.macro';

import { ChartIcon, MoneyIcon, PgIcon } from '@/assets/svg';

const menuItems: ItemType<MenuItemType>[] = [
  {
    label: '파트너 메뉴',
    type: 'group',
    children: [
      {
        key: '1',
        label: '월 정산금액 보기',
        icon: <MoneyIcon />,
      },
      {
        key: '2',
        label: '연 정산내역 보기',
        icon: <ChartIcon />,
      },
      {
        key: '3',
        label: 'PG 수수료 보기',
        icon: <PgIcon />,
      },
    ],
  },
  {
    label: '관리자 메뉴',
    type: 'group',
    children: [
      {
        key: '4',
        label: '정산 등록하기',
        icon: <MoneyIcon />,
      },
    ],
  },
];

const Menu = () => {
  return (
    <AntdMenu
      css={[
        tw`(bg-transparent border-0)!`,
        tw`[.ant-menu-item-group-title]:(p-0 font-bold text-[12px] text-[rgba(0, 0 ,0, 0.45)])!`,
        tw`[.ant-menu-item-group]:([& + &]:mt-[64px])!`,
        tw`[.ant-menu-item-group-list]:(mt-[12px])!`,
        tw`[.ant-menu-item]:(flex flex-row items-center gap-[12px] p-[8px] text-[16px] text-[rgba(0, 0 ,0, 0.45)] [&:active]:bg-transparent [&:hover]:bg-transparent)!`,
        tw`[.ant-menu-item-selected]:(text-[rgba(0, 0, 0, 0.88)] bg-transparent)!`,
        tw`[.ant-menu-item-icon]:(w-[24px] h-[24px])!`,
        tw`[.ant-menu-title-content]:(m-0 leading-[1.5])!`,
      ]}
      items={menuItems}
    />
  );
};

export default Menu;
