import { useEffect, useState } from 'react';

export default (width: number) => {
  const [isBelow, setIsBelow] = useState(false);

  useEffect(() => {
    const handler = () => {
      setIsBelow(window.innerWidth < width);
    };

    handler();

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return isBelow;
};
