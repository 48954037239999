import { Tag } from 'antd';
import tw from 'twin.macro';

const Account = () => {
  return (
    <div css={tw`flex flex-row items-center`}>
      <Tag color="blue">파트너</Tag>
      xxx@2ndsyndrome.com
    </div>
  );
};

export default Account;
